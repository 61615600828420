import axios from '@/common/js/request'

export function projectStatus(data){
    const projectStatus = axios({
        url: '/project/projectStatus',
        method: 'get',
        params:data
    })
    return projectStatus
}
export function pcStopPage(data){
    const pcStopPage = axios({
        url: '/stoppages/pcStopPage',
        method: 'get',
        params:data
    })
    return pcStopPage
}
export function pcResumePage(data){
    const pcResumePage = axios({
        url:  '/stoppages/pcResumePage',
        method: 'get',
        params:data
    })
    return pcResumePage
}
export function searchProgressList(data){
    const searchProgressList = axios({
        url:  '/projectProgress/searchProgressList',
        method: 'post',
        data
    })
    return searchProgressList
}
export function searchProgressLatestList(data){
    const searchProgressLatestList = axios({
        url:  '/projectProgress/searchProgressLatestList',
        method: 'post',
        data
    })
    return searchProgressLatestList
}
export function searchProgressUnitList(params){
    const searchProgressUnitList = axios({
        url:  '/projectProgress/searchProgressUnitList',
        method: 'get',
        params
    })
    return searchProgressUnitList
}
export function searchProgressLatestDetail(params){
    const searchProgressLatestDetail = axios({
        url:  '/projectProgress/searchProgressLatestDetail',
        method: 'get',
        params
    })
    return searchProgressLatestDetail
}
export function searchProgressLatestUnitList(params){
    const searchProgressLatestUnitList = axios({
        url:  '/projectProgress/searchProgressLatestUnitList',
        method: 'get',
        params
    })
    return searchProgressLatestUnitList
}
export function searchDailyCheckList(data){
    const searchDailyCheckList = axios({
        url:  '/dailyCheck/searchDailyCheckList',
        method: 'post',
        data
    })
    return searchDailyCheckList
}
export function searchProgressLatestAll(data){
    const searchProgressLatestAll = axios({
        url:  '/projectProgress/searchProgressLatestAll',
        method: 'post',
        data
    })
    return searchProgressLatestAll
}
export function searchDailyCheckDetail(data){
    const searchDailyCheckDetail = axios({
        url:  '/dailyCheck/searchDailyCheckDetail',
        method: 'get',
        params:data
    })
    return searchDailyCheckDetail
}
export function searchDailyCheckInspectionDetail(data){
    const searchDailyCheckInspectionDetail = axios({
        url:  '/dailyCheck/searchDailyCheckInspectionDetail',
        method: 'get',
        params:data
    })
    return searchDailyCheckInspectionDetail
}
export function searchDailyCheckProjectDetail(data){
    const searchDailyCheckProjectDetail = axios({
        url:  '/dailyCheck/searchDailyCheckProjectDetail',
        method: 'get',
        params:data
    })
    return searchDailyCheckProjectDetail
}