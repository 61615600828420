<template>
  <div>
    <div class="content">
      <div class="all">
        <div class="SearchBar" style="padding-left: 20px">
          <el-form  >
          工程编号/名称:
          <el-input style="width: 200px" placeholder="请输入" size="small"  v-model="searchBox.numberOrName"></el-input>
          <span style="padding-left:20px;">工程类型：</span>
          <el-select v-model="searchBox.projectType" clearable placeholder="请选择" size="small">
            <el-option
                v-for="item in typeList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
             <span style="padding-left:20px">工程状态：</span>
          <el-select v-model="searchBox.status" clearable placeholder="请选择" size="small">
            <el-option
                v-for="item in statustypeList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
            <span style="padding-left:20px">提交时间：</span>
          <el-date-picker
              size="small"
              v-model="time"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              start-placeholder="选择开始时间"
          end-placeholder="选择结束时间">
          </el-date-picker>
          <el-button type="primary" class="SearchBtn" size="small" @click="search">查询</el-button>
          <el-button type="primary" class="SearchBtn" size="small" @click="resetData">重置</el-button>
          </el-form>
        </div>
        <div class="table">
         
          <!--数据表格-->
          <el-table
              ref="tableData"
              :data="tableData"
              style="width: 100%;margin: 15px 0;"
              border
              height="calc(100vh - 354px)"
              :stripe="true"
              :default-sort = "{prop: 'createTime', order: 'descending'}">
            <el-table-column type="index" label="序号" width="70" align="center"> </el-table-column>
            <el-table-column prop="projectNumber" label="工程编号" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="projectName" label="工程名称" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="projectType" label="工程类型" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="projectSite" label="工程地点" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="installTeam" label="安装施工队" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="siteManager" label="现场管理员" align="center" show-overflow-tooltip width="100"></el-table-column>
            <el-table-column prop="registerTime" label="停工开始时间" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="explain" label="停工描述" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="realname" label="提交人" align="center" show-overflow-tooltip width="100"></el-table-column>
            <el-table-column prop="createTime" label="提交时间" align="center" show-overflow-tooltip></el-table-column>
          </el-table>
          <div class="pageBox">
            <el-pagination
                :current-page="searchBox.current"
                :background="true"
                :page-sizes="[30, 50, 100]"
                :page-size="searchBox.size"
                layout="total, prev, pager, next, sizes, jumper"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { selectDictListByParentCode } from '@/apis/commonType'
  import {projectStatus,pcStopPage} from "@/RequestPort/report/projectReport"
  import {getDict} from "../../apis/commonType";
export default {
  name: "lockout",
  data(){
    return{
      searchBox:{
        current:1,
        size: 100,
        numberOrName:'',
        projectType:'',
        status:''
      },
      time:[],
      total:null,
      typeList:[],
      statustypeList:[],
      tableData:[]
    }
  },
  mounted() {
    //字典查工程类型
    getDict({parentCode:'2100'}).then(res=>{
      this.typeList = res.data
    })
    //查状态
    projectStatus().then(res=>{
      let arr = []
      res.data.forEach(item=>{
        if(item.id==1||item.id==2||item.id==3){
        }else{
          arr.push(item)
        }
      })
     this.statustypeList = arr
    })
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let t1 = year + "-" + month + "-" + day + " " + "00:00:00";
    let t2 = year + "-" + month + "-" + day + " " + "23:59:59";
    this.time.push(t1);
    this.time.push(t2);
    if(this.time!=null&&this.time.length>0){
      this.searchBox.startTime = this.time[0]
      this.searchBox.endTime = this.time[1]
    }
    this.loadList(this.searchBox);
  },
  methods:{
    handleSizeChange(val){
      this.searchBox.current = 1
      this.searchBox.size = val
      this.loadList(this.searchBox)
    },
    handleCurrentChange(val){
      this.searchBox.current = val
      this.loadList(this.searchBox)
    },
    search(){
      if(this.time!=null&&this.time.length>0){
        this.searchBox.startTime = this.time[0]
        this.searchBox.endTime = this.time[1]
      }else {
        this.searchBox.startTime= ''
        this.searchBox.endTime = ''
      }
      this.searchBox.current = 1
      this.loadList(this.searchBox)
    },
    loadList(obj){
      pcStopPage(obj).then(res=>{
        if(res.code===200){
          this.tableData = res.data.records
          this.total = res.data.total
        }
      })
    },
    resetData() {
      this.searchBox={
        current:1,
        size: 100,
        numberOrName:'',
        projectType:'',
        status:''
      }
      this.time = []
      this.loadList(this.searchBox)
    }
  }
}
</script>

<style scoped>
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
  padding: 0;
}
.SearchBar {
  /* border: 1px solid #ecedf1; */
  border-radius: 5px;
  margin: 10px;
  padding-top: 1%;
  padding-bottom: 1%;
  background-color: #ffffff;
}
.SearchBtn{
  margin-left: 10px;
}
.table {
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
  background-color: #ffffff;
  height: auto;
}
</style>